import React, { useEffect, useState, VFC } from 'react'
import { Button } from '../../common/Button'
import { useTranslation } from 'react-i18next'
import { WizardHeaderStepper } from '../WizardHeaderStepper'
import VAT from './VAT'
import { belongsToEurope } from '../../billing/utils'
import { checkVAT, countries, Country } from 'jsvat-next'
import { useForm } from 'react-hook-form'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { CreateInstallerCompany, useCompanyMutation } from '../../hooks/createCompanyWizard'
import Confirmation from './Confirmation'
import { Spinner } from '../../common/Spinner'
import { useLoading } from '../../hooks/loading'
import { notify } from '../../notification/actions'
import { useHistory } from 'react-router-dom'
import { reloadProfile } from '../../profile/actions'
import { State } from '../../configureStore'
import { getUserCompanyProfile, UserCompanyProfile } from '../../profile/selectors'
import {
    currentStep,
    getBackOrBackToDashboard,
    getDefaultsSteps,
    getNextLabelButton,
    isFirstStep,
    isLastStep,
    nextStep,
    previousStep,
    StepType
} from './companySteps'
import { getBrandsList, getVatCountry } from './utils'
import InfoCompany from './InfoCompany'
import { useExistingCompany } from '../../hooks/createCompanyWizard/useExistingCompany'

type Props = {
    profile: UserCompanyProfile
    onError: (errorDescription: string) => void
    onSuccess: () => void
}

export type VatNumberOption = 'withvat' | 'withoutvat'

export const CreateCompanyWizard: VFC<Props> = (props: Props) => {
    const { name, surname, company, country } = props.profile
    const { t } = useTranslation()
    const history = useHistory()
    const [steps, setSteps] = useState<StepType[]>(getDefaultsSteps())
    const [companyType, setCompanyType] = useState<'freelance' | 'installer'>('installer')
    const [vatNumber, setVatNumber] = useState<string>('')
    const [vatNumberCountry, setVatNumberCountry] = useState<string>(getVatCountry(country))
    const [isVatNumberValid, setIsVatNumberValid] = useState<boolean>(false)
    const [vatNumberOption, setVatNumberOption] = useState<VatNumberOption>('withvat')
    const useFormProps = useForm({
        mode: 'onChange',
        defaultValues: {
            actualBrands: [],
            fiscalCode: '',
            distributor: null,
            vatnumberPrefix: getVatCountry(country) || '',
            ...company
        }
    })
    const { handleSubmit, watch } = useFormProps
    const watchCountry = watch('country', country)

    const vatValue = vatNumberCountry + vatNumber

    const companyMutation = useCompanyMutation()
    const { create, requestToJoin } = companyMutation

    const useLoadingProp = useLoading()
    const loadingExecute = useLoadingProp.loading
    const execute = useLoadingProp.execute

    const {
        existingCompany
    } = useExistingCompany({
        vatNumber: isVatNumberValid ? vatValue : undefined
    })

    const showLoading = () => {
        const step = currentStep(steps)
        return loadingExecute && (['companyInfo', 'vatNumber', 'confirmation'].includes(step))
    }

    const shouldPrevButtonBeDisabled = () => currentStep(steps) === 'confirmation'
    const shouldNextButtonBeDisabled = () => {
        let isDisabled
        switch (currentStep(steps)) {
            case 'vatNumber':
                isDisabled = !isVatNumberValid && vatNumberOption === 'withvat'
                break
            case 'companyInfo':
            case 'confirmation':
            default:
                isDisabled = false
                break
        }
        return showLoading() || isDisabled
    }

    const onHandleBack = () => {
        if (isFirstStep(steps)) {
            history.push('/dashboard')
        } else {
            previousStep(steps, setSteps)
        }
    }

    const onHandleNext = async () => {
        try {
            if (isLastStep(steps)) {
                history.push('/dashboard')
            } else {
                nextStep(steps, setSteps, !!existingCompany)
            }
        } catch (e) {
            props.onError('error')
        }
    }

    useEffect(() => {
        if (vatNumberOption === 'withoutvat' && belongsToEurope(watchCountry))
            setCompanyType('freelance')
        else
            setCompanyType('installer')
    }, [watchCountry, vatNumberOption, setCompanyType])

    useEffect(() => {
        const countryForValidation = countries.find(c => c.codes[0] === vatNumberCountry || (c.name === 'Greece' && vatNumberCountry === 'EL'))

        if (countryForValidation) {
            const { isValid } = checkVAT(vatNumberCountry + vatNumber, [countryForValidation as Country])
            setIsVatNumberValid(isValid)
        } else {
            setVatNumberOption('withoutvat')
        }
    }, [vatNumberCountry, vatNumber, setIsVatNumberValid])

    const doRequestToJoin = async (id: string) => {
        try {
            await execute(async () => {
                await requestToJoin(id)
            })
            // dispatch(notify('info', t('requestToJoinTeamSuccess'), 3000))
            // setTimeout(() => history.push('/dashboard'), 3000)
            history.push('/dashboard')
        } catch (e) {
            props.onError(t('requestToJoinTeamError'))
        }
    }


    function getCurrentStepComponent() {
        switch (currentStep(steps)) {
            case 'vatNumber':
                return <VAT
                    vatNumber={vatNumber}
                    setVatNumber={setVatNumber}
                    setVatNumberCountry={setVatNumberCountry}
                    isValid={isVatNumberValid}
                    vatNumberCountry={vatNumberCountry}
                    setVatNumberOption={setVatNumberOption}
                    vatNumberOption={vatNumberOption}
                    useFormProps={useFormProps}
                />
            case 'companyInfo':
                return <InfoCompany
                    country={country}
                    vatNumber={vatNumber}
                    vatNumberCountry={vatNumberCountry}
                    brands={getBrandsList(t)}
                    companyType={companyType}
                    useFormProps={useFormProps}
                />
            case 'confirmation':
                return <Confirmation
                    existingCompany={existingCompany || undefined}
                    onHandleClick={() => doRequestToJoin(existingCompany?._id || '')}
                />
            default:
                return null
        }
    }

    const doSubmit = async (data: any) => {
        try {
            const { distributor, countryPhoneNumber, phoneNumber, businessName, ...other } = data
            const distributors = distributor && distributor.value !== 'none' ? [distributor.value] : []

            const company = {
                ...other,
                distributors,
                type: companyType,
                phoneNumber: countryPhoneNumber + phoneNumber,
                businessName: businessName || `${surname} ${name}`
            }

            await execute(async () => {
                const { pec, cuu } = company as CreateInstallerCompany
                const body = {
                    ...company,
                    pec: pec !== '' ? pec : undefined,
                    cuu: cuu !== '' ? cuu : undefined,
                    vatNumber: vatNumber !== '' ? vatValue : undefined
                } as CreateInstallerCompany
                await create(body)
            })
            props.onSuccess()
            await onHandleNext()
        } catch (e: any) {
            props.onError(e.code)
        }
    }

    return (
        <div className="grid grid-rows-[80px,1fr] h-screen bg-white relative">
            <Spinner show={showLoading()}/>
            <div className="grid items-center px-4 border-b border-gray-100 bg-gray-50">
                <WizardHeaderStepper steps={steps}/>
            </div>
            <form
                className="grid grid-rows-[calc(100vh-200px),70px] lg:grid-rows-[calc(100vh-150px),70px] h-full"
                action="#" method="POST"
                onSubmit={(e) => {
                    e.preventDefault()
                    if (currentStep(steps) === 'companyInfo') {
                        handleSubmit(doSubmit)(e)
                    } else
                        onHandleNext()
                }}>
                {
                    <div data-testid={'main'}
                         className="relative overflow-hidden overflow-y-auto">
                        {getCurrentStepComponent()}
                    </div>
                }
                <div className="px-4 flex items-center w-full justify-between border-t border-gray-100 bg-gray-50">
                    <Button
                        type="button"
                        text={t(getBackOrBackToDashboard(steps))}
                        color="white"
                        size="lg"
                        disabled={shouldPrevButtonBeDisabled()}
                        onClick={onHandleBack}
                    />
                    <Button
                        type="submit"
                        text={t(getNextLabelButton(steps))}
                        color="primary"
                        size="lg"
                        disabled={shouldNextButtonBeDisabled()}
                    />
                </div>
            </form>
        </div>
    )
}

export function mapStateToProps(state: State) {
    return {
        profile: getUserCompanyProfile(state) as UserCompanyProfile
    }
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onError(error: string) {
            dispatch(notify('error', error))
        },
        onSuccess() {
            dispatch(reloadProfile())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompanyWizard)