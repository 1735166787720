
import React, { useMemo, useState } from 'react'
import { Spinner } from '../common/Spinner'
import { FilesSubtype, useDownloads } from './useDownloads'
import { ArrowRightIcon } from '@heroicons/react/outline'
import Card from '../common/Card/Card'
import { useTranslation } from 'react-i18next'
import ListSearchable from '../common/ListSearchable'
import { ModalDialog } from '../common/ModalDialog'
import { AlertInfo } from '../common/Alert/Alert'
import FileListItem from './FileListItem'
import _ from 'lodash'

export type DownloadType = 'products' | 'certifications' | 'marketing'

export function DownloadList(props: {
    type: DownloadType
    titleLabel: string
    alertLabel?: string
    requireAuth: boolean
    favourites?: string[]
}) {
    const { type, titleLabel, alertLabel, requireAuth, favourites = [] } = props

    const { t } = useTranslation()

    const { items, loadingItems, selectFile, loadingUrl } = useDownloads({
        type,
        auth: requireAuth,
        onLoadFileUrl: url => window.open(url, '_blank', 'noreferrer')
    })

    /* sort groups alphabetically */
    const sortedItems = useMemo(() => _.orderBy(
        items.map(item => ({ ...item, localizedKey: t(item.key) })),
        [item => item.localizedKey.toLowerCase()],
        ['asc']
    ), [items, t])

    /* remove favourites if category non present in fetched data */
    const fav = useMemo(() => {
        return sortedItems.filter(({ key }) => favourites.includes(key.toLowerCase()))
    }, [favourites, sortedItems])

    const [activeItem, setActiveItem] = useState<FilesSubtype>()
    const [show, setShow] = useState(false)

    const onProductClickHandler = (files: FilesSubtype) => () => {
        setShow(true)
        setActiveItem(files)
    }

    const onCloseHandler = () => setShow(false)
    const onSelectFileHandler = (id: string) => () => selectFile(id)

    return (
        <>
            <Spinner show={loadingItems || loadingUrl} containerClass="!fixed left-auto right-auto bottom-auto top-auto h-screen w-full lg:w-[calc(100%-16rem)]" />
            <h2 className="title-page">{t(titleLabel)}</h2>
            <div className="page-container">
                {alertLabel ? <AlertInfo containerClass="shadow mb-8" message={t(alertLabel)} /> : null}
                {fav.length ? <div className="mb-16">
                    <h3 className="mb-2 text-sm uppercase text-gray-500">{t('most-wanted')}</h3>
                    <ul className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                        {fav.map((group) => (
                            <li key={group.key}>
                                <Card>
                                    <button data-testid={`fav-${group.key}`} type="button" className="flex flex-row items-center justify-between pl-3 sm:pl-6 cursor-pointer hover:bg-blue-100 w-full text-gray-700" onClick={onProductClickHandler(group)}>
                                        <span className="truncate max-w-[calc(100%-3rem)]">{t(group.key)}</span>
                                        <div className="flex items-center justify-center bg-blue-100 w-12 h-12">
                                            <ArrowRightIcon className="w-6 h-6 text-blue-400" />
                                        </div>
                                    </button>
                                </Card>
                            </li>
                        ))}
                    </ul>
                </div> : null}
                <ListSearchable keys={['title', 'files.recommendedDescription']} data={sortedItems.map(i => ({ ...i, title: i.localizedKey }))} render={(item) => (
                    <button key={item.key} type="button" className="flex items-center justify-between px-3 sm:px-6 py-4 cursor-pointer hover:bg-gray-50 w-full" onClick={onProductClickHandler(item)}>
                        <span className="text-brand-500">{t(item.key)}</span>
                        <ArrowRightIcon className="w-6 h-6 text-gray-500" />
                    </button>
                )} />
            </div>
            <ModalDialog title={t(activeItem?.key || '')} open={show} onClose={onCloseHandler}>
                <div className="flex-1 overflow-y-auto">
                    <ul className="divide-y divide-gray-100">
                        {activeItem?.files.map((file) => (
                            <li key={file.id}>
                                <FileListItem
                                    title={file.recommendedDescription}
                                    bytes={file.bytes}
                                    contentType={file.contentType}
                                    onClick={onSelectFileHandler(file.id)} />
                            </li>
                        ))}
                    </ul>
                </div>
            </ModalDialog>
        </>
    )
}